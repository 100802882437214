import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import { MobileAndTabletQuery, DesktopQuery } from "../../lib/utils";

const FeatureDescription = ({
  children,
  isActive,
  onClick,
  swipeTo,
  title,
  id,
}) => {
  const animationVariants = {
    visible: {
      display: "block",
      position: "relative",
      transitionStart: {
        display: "block",
      },
      opacity: 1,
    },
    hidden: {
      opacity: 0,
      position: "absolute",
      transitionStart: {
        opacity: 0.2,
        position: "absolute",
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <>
      <DesktopQuery>
        <button
          type="button"
          id={id}
          className={`flex flex-col focus:outline-none animate  rounded-lg p-5 ${
            isActive ? "bg-mint-500" : "bg-transparent"
          }`}
          onClick={onClick}
        >
          <div className="text-xl md:text-2xl font-bold text-left leading-none mb-2">
            {title}
          </div>
          <p className="text-left text-lg font-light">{children}</p>
        </button>
      </DesktopQuery>
      <MobileAndTabletQuery>
        <motion.button
          type="button"
          id={id}
          className="flex flex-col items-center focus:outline-none p-5"
          onClick={swipeTo}
          initial={false}
          animate={isActive ? "visible" : "hidden"}
          variants={animationVariants}
        >
          <div className="text-xl md:text-2xl font-bold text-center leading-none mb-4">
            {title}
          </div>
          <p className="text-center text-lg font-light">{children}</p>
        </motion.button>
      </MobileAndTabletQuery>
    </>
  );
};

FeatureDescription.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  swipeTo: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
};

FeatureDescription.defaultProps = {
  isActive: false,
  onClick: () => {},
  swipeTo: () => {},
  title: "",
  id: "",
};

export default FeatureDescription;
