import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import appointerCalendarImage from "../../images/calendars-not-shown/appointer-calendar.png";
import calendarsNotShownHeader from "../../images/calendars-not-shown/calendars-not-shown-header.png";
import privateCalendarHeader from "../../images/calendars-not-shown/private-calendar-header.png";
import privateCalendarImage from "../../images/calendars-not-shown/private-calendar.png";
import mobileCalendarNotShown from "../../images/calendars-not-shown/mobile-calendar-not-shown.png";
import { MobileQuery, TabletQuery } from "../../lib/utils";

const CalendarsNotShown = ({ isVisible }) => {
  const [showAppointerCalendar, setShowAppointerCalendar] = useState(false);

  const handleAnimationComplete = () => {
    if (!showAppointerCalendar) {
      setTimeout(() => {
        setShowAppointerCalendar(true);
      }, 1200);
    }
  };

  useEffect(() => {
    if (!isVisible) {
      setShowAppointerCalendar(false);
    } else {
      handleAnimationComplete();
    }
  }, [isVisible]);

  return (
    <>
      <TabletQuery>
        <div className="flex flex-row items-end w-full relative min-h-96 overflow-hidden">
          <motion.div
            initial={false}
            animate={showAppointerCalendar ? { opacity: 0.5 } : { opacity: 1 }}
            className="flex flex-col"
            onAnimationComplete={handleAnimationComplete}
          >
            <div className="relative h-12 mb-3">
              <img
                src={privateCalendarHeader}
                className="h-full w-auto"
                alt=""
              />
            </div>
            <div className="relative w-full">
              <img
                src={privateCalendarImage}
                style={{ height: 436 }}
                alt=""
                className="max-w-none"
              />
            </div>
          </motion.div>
          <motion.div
            className="absolute right-0 top-0 h-full"
            initial={{ opacity: 0, scale: 0.8, marginRight: -150 }}
            animate={
              showAppointerCalendar
                ? {
                    opacity: 1,
                    scale: 1,
                    marginRight: 0,
                    transitionStart: { opacity: 0.8 },
                  }
                : { opacity: 0, scale: 0.8, marginRight: -150 }
            }
          >
            <div className="relative h-12 mb-3 bg-white">
              <img
                src={calendarsNotShownHeader}
                className="h-full w-auto"
                alt=""
              />
            </div>
            <div className="flex-1 overflow-hidden">
              <img
                src={appointerCalendarImage}
                className="max-h-full h-auto w-auto flex-shrink-1"
                alt=""
                style={{ height: 436 }}
              />
            </div>
          </motion.div>
        </div>
      </TabletQuery>
      <MobileQuery>
        <img src={mobileCalendarNotShown} alt="" />
      </MobileQuery>
    </>
  );
};

CalendarsNotShown.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default CalendarsNotShown;
