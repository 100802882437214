import React, { useState } from "react";
import PropTypes from "prop-types";
import { StickyContainer, Sticky } from "react-sticky";
import SwipeableViews from "react-swipeable-views";

import FeatureIllustrations from "./FeatureIllustration";
import Dot from "./Dot";
import { DesktopQuery, MobileAndTabletQuery } from "../../lib/utils";
import FeatureDescription from "./FeatureDescription";

const MultifeatureSpotlightRow = ({ children, reverseRow }) => {
  const [activeElement, setActiveElement] = useState(0);
  const childrenCount = React.Children.count(children);

  const onClick = id => {
    setActiveElement(id);
  };

  const swipeTo = (id, direction) => {
    if (direction === "right") {
      setActiveElement(id + 1 < childrenCount ? id + 1 : 0);
      return;
    }

    setActiveElement(id > 0 ? id - 1 : childrenCount - 1);
  };

  const featureDescriptions = React.Children.map(children, (child, idx) => {
    return (
      <FeatureDescription
        isActive={activeElement === idx}
        key={`multi-feature-description-${idx}`}
        swipeTo={() => {
          swipeTo(idx, "right");
        }}
        onClick={() => {
          onClick(idx);
          if (child.props.onClick) {
            child.props.onClick();
          }
        }}
        title={child.props.title}
      >
        {child.props.description}
      </FeatureDescription>
    );
  });

  const featureIllustrations = React.Children.map(children, (child, idx) => {
    return (
      <FeatureIllustrations isActive={activeElement === idx}>
        {React.cloneElement(child, {
          isActive: activeElement === idx,
          key: `multi-feature-illustrarion-${idx}`,
        })}
      </FeatureIllustrations>
    );
  });

  const dotSwitcher = React.Children.map(children, (child, idx) => {
    return (
      <Dot
        isActive={activeElement === idx}
        key={`multi-feature-dot-${idx}`}
        onClick={() => {
          onClick(idx);
          if (child.props.onClick) {
            child.props.onClick();
          }
        }}
      />
    );
  });

  return (
    <>
      <DesktopQuery>
        <StickyContainer>
          <div
            className={`flex ${reverseRow ? "flex-row-reverse" : "flex-row"} `}
          >
            <div className="flex flex-col w-1/2 px-5">
              <div
                className={`flex w-full ${
                  reverseRow ? "flex-row-reverse" : "flex-row"
                } flex-no-wrap`}
              >
                <div className="flex flex-col flex-no-shrink items-center justify-center mb-0 mx-4 w-6">
                  {dotSwitcher}
                </div>
                <div className="flex flex-col">{featureDescriptions}</div>
              </div>
            </div>
            <div className="flex flex-col w-1/2 px-5 relative">
              <Sticky topOffset={-100}>
                {({ style, isSticky }) => (
                  <div style={{ ...style, paddingTop: isSticky ? 100 : 0 }}>
                    <div className="w-full relative">
                      {featureIllustrations}
                    </div>
                  </div>
                )}
              </Sticky>
            </div>
          </div>
        </StickyContainer>
      </DesktopQuery>
      <MobileAndTabletQuery>
        <div className="flex flex-col">
          <div className="flex flex-ro flex-no-shrink items-center justify-center w-full">
            {dotSwitcher}
          </div>
          <div className="flex flex-col">
            <div className="relative w-full">{featureDescriptions}</div>
          </div>
          <div className="flex flex-col w-full relative">
            <SwipeableViews
              enableMouseEvents
              index={activeElement}
              onChangeIndex={index => setActiveElement(index)}
            >
              {featureIllustrations}
            </SwipeableViews>
          </div>
        </div>
      </MobileAndTabletQuery>
    </>
  );
};

MultifeatureSpotlightRow.propTypes = {
  reverseRow: PropTypes.bool,
};

MultifeatureSpotlightRow.defaultProps = {
  reverseRow: false,
};

export default MultifeatureSpotlightRow;
