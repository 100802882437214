import React from "react";
import PropTypes from "prop-types";

const Feature = ({ children, isActive }) => {
  if (typeof children === "function") {
    return <>{children(isActive)}</>;
  }
  return children;
};

Feature.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  swipeTo: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
};

Feature.defaultProps = {
  isActive: false,
  onClick: () => {},
  swipeTo: () => {},
  title: "",
  id: "",
};

export default Feature;
