import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import ReminderEmailImage from "../../images/reminder-email.png";
import ReminderVideo from "../../images/reminder.mp4";

const AnimationEmailReminders = ({ isVisible }) => {
  const [showReminderEmail, setShowReminderEmail] = useState(false);

  const ReminderEmailVideoRef = useRef();

  useEffect(() => {
    if (isVisible) {
      ReminderEmailVideoRef.current.currentTime = 0;
      ReminderEmailVideoRef.current.play();
    } else {
      setShowReminderEmail(false);
    }
  }, [isVisible]);

  const handleRemidnerEmailVideoEnd = () => {
    setShowReminderEmail(true);
  };

  return (
    <div className="flex flex-col justify-center w-full relative min-h-96">
      <motion.video
        animate={showReminderEmail ? { opacity: 0.4 } : { opacity: 1 }}
        className="bg-white rounded-lg shadow-lg"
        ref={ReminderEmailVideoRef}
        muted
        playsInline
        onEnded={() => {
          handleRemidnerEmailVideoEnd();
        }}
      >
        <source src={ReminderVideo} type="video/mp4" />
      </motion.video>
      <motion.img
        className="absolute right-0 top-0 max-w-full max-h-full"
        alt="reminder email"
        src={ReminderEmailImage}
        initial={{ opacity: 0, scale: 0.8, marginRight: -150 }}
        animate={
          showReminderEmail
            ? {
                opacity: 1,
                scale: 1,
                marginRight: -10,
                transitionStart: { opacity: 0.8 },
              }
            : { opacity: 0, scale: 0.8, marginRight: -150 }
        }
      />
    </div>
  );
};

AnimationEmailReminders.propTypes = {
  isVisible: PropTypes.bool,
};

AnimationEmailReminders.defaultProps = {
  isVisible: "",
};

export default AnimationEmailReminders;
