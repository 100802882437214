import React, { useRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { motion } from "framer-motion";

const AccordionElement = ({
  isOpen,
  onClick,
  children,
  title,
  id,
  ...props
}) => {
  const elementRef = useRef();

  const animationVariants = {
    visible: {
      opacity: 1,
      height: "100%",
      transitionStart: {
        height: "0%",
        display: "block",
      },
    },
    hidden: {
      opacity: 0,
      height: 0,
      transitionStart: {
        height: "20%",
        opacity: 0.2,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <div
      className={`flex flex-col w-full animate ${
        isOpen ? "bg-mint-500" : "bg-white"
      } rounded-lg p-5`}
      ref={elementRef}
      id={id}
      {...props}
    >
      <button
        type="button"
        className={`flex flex-row items-center focus:outline-none animate ${
          isOpen ? "mb-4" : "mb-0"
        }`}
        onClick={() => {
          onClick();
        }}
      >
        <span className="text-xl md:text-2xl font-bold text-left leading-none">
          {title}
        </span>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
          initial={false}
          className="ml-2"
        >
          <FontAwesomeIcon size="1x" icon={faChevronDown} />
        </motion.div>
      </button>
      <motion.div
        animate={isOpen ? "visible" : "hidden"}
        variants={animationVariants}
        className="w-full overflow-hidden text-lg font-light"
        initial={false}
      >
        {children}
      </motion.div>
    </div>
  );
};

AccordionElement.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
};

AccordionElement.defaultProps = {
  isOpen: false,
  onClick: () => {},
  title: "",
  id: "",
};

export default AccordionElement;
