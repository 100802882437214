import React from "react";
import PropTypes from "prop-types";

const TestimonialRow = ({
  image,
  logo,
  name,
  companyRole,
  className,
  children,
}) => {
  return (
    <div className="container mx-auto">
      <div className={`flex flex-row flex-wrap bg-yellow-400 p-8 ${className}`}>
        <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 mb-12 md:mb-0">
          <div>
            <img src={image} className="w-full" alt="" />
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/2 lg:w-2/3 md:pl-12">
          <div className="flex flex-col flex-grow">
            <div className="flex flex-col flex-grow justify-center text-xl md:text-3xl font-light italic lg:w-2/3">
              {children}
            </div>
            <div className="flex flex-col w-full items-end mt-8">
              <div className="flex flex-row flex-wrap justify-end text-md mb-2">
                <span className="font-bold">{name},</span>
                <span className="ml-1">{companyRole}</span>
              </div>
              <img src={logo} width={150} alt="wework logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TestimonialRow.propTypes = {
  image: PropTypes.object.isRequired,
  logo: PropTypes.object,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  companyRole: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  className: PropTypes.string,
};

TestimonialRow.defaultProps = {
  logo: {},
  className: "",
};

export default TestimonialRow;
