import React from "react";
import PropTypes from "prop-types";

const Dot = ({ isActive, onClick }) => (
  <button
    type="button"
    className="p-4 lg:p-0 lg:py-2 focus:outline-none"
    onClick={onClick}
  >
    <div
      className={`w-4 h-4 lg:w-3 lg:h-3 rounded-full border-2 animate ${
        isActive ? "bg-mint-500 border-mint-500" : "bg-gray-100 border-gray-200"
      }`}
    />
  </button>
);

Dot.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

Dot.defaultProps = {
  isActive: false,
  onClick: () => {},
};

export default Dot;
