import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const FeatureIllustrations = ({ children, isActive }) => {
  const animationVariants = {
    visible: {
      display: "block",
      position: "relative",
      transitionStart: {
        display: "block",
      },
      opacity: 1,
    },
    hidden: {
      opacity: 0,
      position: "absolute",
      transitionStart: {
        opacity: 0.2,
        position: "absolute",
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <motion.div
      initial={false}
      animate={isActive ? "visible" : "hidden"}
      variants={animationVariants}
      className="w-full left-0 top-0 relative rounded-lg"
    >
      {children}
    </motion.div>
  );
};

FeatureIllustrations.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default FeatureIllustrations;
