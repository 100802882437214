import React, { useState } from "react";
import { motion } from "framer-motion";
import { FormattedMessage, useIntl } from "react-intl";

import FormattedHTMLMessage from "../components/FormattedHTMLMessage";

import SEO from "../components/seo";
import ExternalLink from "../components/ExternalLink";
import Accordion from "../components/accordion/Accordion";
import AccordionElement from "../components/accordion/AccordionElement";
import Feature from "../components/multifeature-spotlight-row/Feature";
import ConversionRow from "../components/ConversionRow";

import MultifeatureSpotlightRow from "../components/multifeature-spotlight-row/MultifeatureSpotlightRow";

import {
  MobileAndTabletQuery,
  DesktopQuery,
  fireCustomEventToDataLayer,
} from "../lib/utils";
import TestimonialRow from "../components/TestimonialRow";

import WeworkLogo from "../images/testimonials/wework/wework-logo.png";
import SabelaGarcia from "../images/testimonials/wework/sabela-garcia-cuesta.png";

import BlockerGoogleCalendarVideo from "../images/blocker-google-calendar.mp4";
import RecommendationVideo from "../images/recommendation.mp4";
import NotAllAttendeesNeedToHaveAppointerAccountVideo from "../images/not-all-attendees-need-to-have-appointer-account.mp4";
import AppointerMeetChatVideo from "../images/appointer-meet-chat.mp4";
import IphoneWhatsappLinkImage from "../images/iphone-whatsapp.png";
import VideoContainer from "../components/VideoContainer";

import LightBulb from "../images/light-bulb.png";
import CalendarsNotShown from "../components/animation-items/CalendarsNotShown";
import AnimationEmailReminders from "../components/animation-items/AnimationEmailReminders";

const PICTURE_STEP_1 = "picture-step-1";
const PICTURE_STEP_2 = "picture-step-2";
const PICTURE_STEP_3 = "picture-step-3";

const WhyAppointerPage = () => {
  const intl = useIntl();
  const [activePicture, setActivePicture] = useState(PICTURE_STEP_1);

  const animationVariants = {
    visible: {
      display: "block",
      position: "relative",
      transitionStart: {
        display: "block",
        position: "relative",
      },
      opacity: 1,
    },
    hidden: {
      opacity: 0,
      position: "absolute",
      transitionStart: {
        opacity: 0.2,
        position: "absolute",
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <>
      <SEO
        title="why-appointer.seo.title"
        description="why-appointer.seo.description"
      />
      <div className="bg-cream relative">
        <div className="container mx-auto">
          <div className="relative flex flex-col-reverse lg:flex-row lg:flex-no-wrap z-20 relative justify-between items-center pt-32 py-24 lg:py-40">
            <div className="flex flex-col items-center">
              <h1 className="text-center leading-tight mb-6">
                <FormattedMessage id="why-appointer.headline" />
              </h1>
              <span className="sub-headline">
                <FormattedMessage id="why-appointer.sub-headline" />
              </span>
              <div className="mt-12 flex flex-row items-center">
                <ExternalLink
                  to="https://meet.appointer.com"
                  className="button shadow-none button-xl mx-2 ga-start-now-button"
                >
                  <FormattedMessage id="common.start-scheduling" />
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center flex-wrap-reverse lg:flex-no-wrap justify-between mt-16 container mx-auto">
        <div className="flex flex-col items-center lg:items-start w-full lg:w-1/3">
          <div className="px-4">
            <h2 className="text-center lg:text-left">
              <FormattedMessage id="why-appointer.accordion.headline" />
            </h2>
            <p className="text-center lg:text-left text-xl lg:text-2xl mt-4 leading-none">
              <FormattedMessage id="why-appointer.accordion.sub-headline" />
            </p>
          </div>

          <Accordion className="mt-12">
            <AccordionElement
              title={intl.formatMessage({
                id: "why-appointer.accordion.feature-1.title",
              })}
              onClick={() => {
                setActivePicture(PICTURE_STEP_1);
                fireCustomEventToDataLayer({
                  event: "Custom Click",
                  ClickEventID: "ga-Oue3gR",
                });
              }}
            >
              <FormattedMessage id="why-appointer.accordion.feature-1.text" />
              <MobileAndTabletQuery>
                <div className="w-fullleft-0 top-0 relative rounded-lg mt-8">
                  <VideoContainer
                    src={RecommendationVideo}
                    className="bg-white rounded-lg shadow-lg"
                    loop
                  />
                </div>
              </MobileAndTabletQuery>
            </AccordionElement>
            <AccordionElement
              title={intl.formatMessage({
                id: "why-appointer.accordion.feature-2.title",
              })}
              onClick={() => {
                setActivePicture(PICTURE_STEP_2);
                fireCustomEventToDataLayer({
                  event: "Custom Click",
                  ClickEventID: "ga-0uwVgZ",
                });
              }}
            >
              <FormattedMessage id="why-appointer.accordion.feature-2.text" />
              <MobileAndTabletQuery>
                <div className="w-full left-0 top-0 relative rounded-lg mt-8">
                  <VideoContainer
                    src={BlockerGoogleCalendarVideo}
                    className="bg-white rounded-lg shadow-lg"
                    loop
                  />
                </div>
              </MobileAndTabletQuery>
            </AccordionElement>
            <AccordionElement
              title={intl.formatMessage({
                id: "why-appointer.accordion.feature-3.title",
              })}
              onClick={() => {
                setActivePicture(PICTURE_STEP_3);
                fireCustomEventToDataLayer({
                  event: "Custom Click",
                  ClickEventID: "ga-XmhR7A",
                });
              }}
            >
              <FormattedMessage id="why-appointer.accordion.feature-3.text" />
              <MobileAndTabletQuery>
                <div className="w-full left-0 top-0 relative rounded-lg mt-8">
                  <AnimationEmailReminders
                    isVisible={activePicture === PICTURE_STEP_3}
                  />
                </div>
              </MobileAndTabletQuery>
            </AccordionElement>
          </Accordion>
        </div>
        <DesktopQuery>
          <div className="flex flex-col w-1/2 lg:w-2/3 px-4 pl-24">
            <div className="relative w-full">
              <motion.div
                initial={false}
                animate={
                  activePicture === PICTURE_STEP_1 ? "visible" : "hidden"
                }
                variants={animationVariants}
                className="w-full top-0 left-0"
              >
                <VideoContainer
                  src={RecommendationVideo}
                  className="bg-white rounded-lg shadow-lg"
                  loop
                />
              </motion.div>
              <motion.div
                initial={false}
                animate={
                  activePicture === PICTURE_STEP_2 ? "visible" : "hidden"
                }
                variants={animationVariants}
                className="w-full top-0 left-0"
              >
                <VideoContainer
                  src={BlockerGoogleCalendarVideo}
                  className="bg-white rounded-lg shadow-lg"
                  loop
                />
              </motion.div>
              <motion.div
                initial={false}
                animate={
                  activePicture === PICTURE_STEP_3 ? "visible" : "hidden"
                }
                variants={animationVariants}
                className="w-full top-0 left-0"
              >
                <AnimationEmailReminders
                  isVisible={activePicture === PICTURE_STEP_3}
                />
              </motion.div>
            </div>
          </div>
        </DesktopQuery>
      </div>
      <div className="mt-32 container mx-auto">
        <div className="flex flex-col items-center p-8 pt-6 rounded-lg bg-cream">
          <img src={LightBulb} alt="" className="w-12" />
          <span className="h2 ml-4 mb-2 text-center pb-0 leading-tight">
            <FormattedMessage id="why-appointer.hint.title" />
          </span>

          <p className="text-xl text-center">
            <FormattedHTMLMessage id="why-appointer.hint.text" />
          </p>
        </div>
      </div>
      <div className="flex flex-col mt-32 container mx-auto">
        <h2 className="text-center">
          <FormattedHTMLMessage id="why-appointer.multi-feature.headline" />
        </h2>
        <div className="w-full mt-8 lg:mt-16">
          <MultifeatureSpotlightRow reverseRow>
            <Feature
              title={intl.formatMessage({
                id: "why-appointer.multi-feature.feature-1.title",
              })}
              description={intl.formatMessage({
                id: "why-appointer.multi-feature.feature-1.description",
              })}
              onClick={() => {
                fireCustomEventToDataLayer({
                  event: "Custom Click",
                  ClickEventID: "ga-yv3nhg",
                });
              }}
            >
              <div className="flex flex-col w-full bg-white md:p-4 md:shadow-lg md:rounded-lg">
                <div className="flex flex-row my-2 -mx-2">
                  <div className="rounded-full h-3 w-3 bg-red-500" />
                  <div className="rounded-full h-3 w-3 bg-yellow-300 mx-1" />
                  <div className="rounded-full h-3 w-3 bg-green-300" />
                </div>
                <VideoContainer src={AppointerMeetChatVideo} />
              </div>
            </Feature>
            <Feature
              onClick={() => {
                fireCustomEventToDataLayer({
                  event: "Custom Click",
                  ClickEventID: "ga-BS2zQD",
                });
              }}
              title={intl.formatMessage({
                id: "why-appointer.multi-feature.feature-2.title",
              })}
              description={intl.formatMessage({
                id: "why-appointer.multi-feature.feature-2.description",
              })}
            >
              {isActive => {
                return <CalendarsNotShown isVisible={isActive} />;
              }}
            </Feature>
            <Feature
              onClick={() => {
                fireCustomEventToDataLayer({
                  event: "Custom Click",
                  ClickEventID: "ga-Dk5x4t",
                });
              }}
              title={intl.formatMessage({
                id: "why-appointer.multi-feature.feature-3.title",
              })}
              description={intl.formatMessage({
                id: "why-appointer.multi-feature.feature-3.description",
              })}
            >
              <VideoContainer
                src={NotAllAttendeesNeedToHaveAppointerAccountVideo}
              />
            </Feature>
            <Feature
              onClick={() => {
                fireCustomEventToDataLayer({
                  event: "Custom Click",
                  ClickEventID: "ga-J6eoJG",
                });
              }}
              title={intl.formatMessage({
                id: "why-appointer.multi-feature.feature-4.title",
              })}
              description={intl.formatMessage({
                id: "why-appointer.multi-feature.feature-4.description",
              })}
            >
              <div className="flex flex-row justify-center w-full">
                <div className="max-w-xs p-4">
                  <img src={IphoneWhatsappLinkImage} alt="" />
                </div>
              </div>
            </Feature>
          </MultifeatureSpotlightRow>
        </div>
      </div>
      <TestimonialRow
        logo={WeworkLogo}
        image={SabelaGarcia}
        name="Sabela Garcia Cuesta"
        companyRole="WeWork Labs Mangager"
        className="mt-16 md:mt-32"
      >
        <div className="text-xl md:text-2xl lg:text-3xl">
          “I have tested appointer to organize an afterwork event with +20
          attendees. It runs very smooth and
          <span className="font-bold mx-2">it is faster</span>
          than other manual alternatives. I can only recommend it“
        </div>
      </TestimonialRow>
      <ConversionRow />
    </>
  );
};

export default WhyAppointerPage;
