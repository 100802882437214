import React, { useState } from "react";
import PropTypes from "prop-types";
import AccordionElement from "./AccordionElement";

const Accordion = ({
  allowMultipleOpen,
  allowAllClosed,
  children,
  className,
  ...props
}) => {
  const [openElements, setOpenElements] = useState(!allowAllClosed ? [0] : []);

  const onClick = id => {
    const currentOpen = [...openElements];
    const index = currentOpen.indexOf(id);

    if (allowMultipleOpen) {
      if (index === -1) {
        // check that the id is not in array, then add
        currentOpen.push(id);
        setOpenElements(currentOpen);
      } else if (currentOpen.length > 1 || allowAllClosed) {
        // check if there is at least one open remaining then remove ref from array
        currentOpen.splice(index, 1);
        setOpenElements(currentOpen);
      }
      return;
    }

    if (index === -1) {
      setOpenElements([id]);

      return;
    }

    if (allowAllClosed) {
      setOpenElements([]);
    }
  };

  const childrenWithProps = React.Children.map(children, (child, idx) => {
    return React.cloneElement(child, {
      isOpen: openElements.indexOf(idx) !== -1,
      key: `accordion-children-${idx}`,
      onClick: () => {
        onClick(idx);
        if (child.props.onClick) {
          child.props.onClick();
        }
      },
    });
  });

  return (
    <div className={`flex flex-row w-full ${className}`} {...props}>
      <div className="flex flex-col w-full">{childrenWithProps}</div>
    </div>
  );
};

const accordionElementType = PropTypes.shape({
  type: PropTypes.oneOf([AccordionElement]),
});

Accordion.propTypes = {
  allowMultipleOpen: PropTypes.bool,
  allowAllClosed: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(accordionElementType), // array of fields
    accordionElementType, // singular field
  ]).isRequired,
};

Accordion.defaultProps = {
  allowMultipleOpen: false,
  allowAllClosed: false,
  className: "",
};

export default Accordion;
